import React, { useState, useEffect } from 'react';
import { Grid, CardMedia } from '@mui/material';

export const ImageUpload=(props)=> {

    const fileInputRef = React.useRef(null);
  
    const handleImageChange = (e) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
  
        // Create a URL for the image file
        const imageUrl = URL.createObjectURL(file);
  
        // Add the new image URL to the images array
        props.setImages([...props.images, {url:imageUrl, file:file}]);
        
  
        // Reset the file input after the file is selected
        e.target.value = null;
      }
    };
  
    const handleClick = () => {
      fileInputRef.current.click();
    };
  
  
    useEffect(() => {
  
    }, [props.images, props.disabled])
  
    const handleDelete = (indexToDelete) => {
      // Map over the images and update the one at indexToDelete
      const updatedImages = props.images.map((image, index) => 
        index === indexToDelete ? { ...image, deleteFlag: true } : image
      );
    
      // Update the state with the modified images array
      props.setImages(updatedImages);
    };

    return (
      <div>
        {props.images.filter(image => image.deleteFlag !== true).map((image, index) => (
          <div key={index} style={{ position: 'relative', display: 'inline-block', margin: '10px' }}>
          <img key={index} src={image.url} alt={`upload-${index}`} style={{ margin: '10px', maxWidth:'350px', maxHeight:'350px' }} />
          {/* <Grid item xs={12} sm = {12} md = {12} style={{ height: '300px', overflow: 'hidden' }}>
            <CardMedia
                component="img"
                image={image.url}
                style={{ 
                    height: '100%', 
                    width: '100%', 
                    objectFit: 'cover',  // Ensures the image covers the entire container
                    objectPosition: 'center' // Centers the image
                }}
            />
        </Grid> */}
          {!props.disabled ? <span
              onClick={() => handleDelete(index)}
              style={{
                position: 'absolute',
                top: 15,
                right: 15,
                cursor: 'pointer',
                padding: '2px 5px',
                background: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '50%',
                border: '1px solid #ccc'
              }}
            >
              X
            </span> : ''}
            </div>
        ))}
        {props.images.filter(image => image.deleteFlag !== true).length < props.limit ? 
        <>
        <div onClick={handleClick} style={{ width: '250px', height: '250px', backgroundColor: '#eee', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
          <span>Upload Image</span>
        </div>
         <input type="file" onChange={handleImageChange} ref={fileInputRef} style={{ display: 'none' }} /> 
         </>: ''}  
      </div>
    );
  }