import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { checkAuth, getRental } from './features/User/actions';
import { CircularProgress } from '@mui/material';

const RentalInformationProtection = ({ children }) => {
  const location = useLocation();
  const { id } = useParams();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const checkAuthorization = async () => {
      try {
        const authResponse = await checkAuth();
        if (!authResponse.user) {
          console.log("HERE WHAT IS GOING ON")
          setIsAuthenticated(false);
          setLoading(false);
          return;
        }
        console.log("HERE passed")
        setIsAuthenticated(true);

        if (id) {
          // Fetch rental details

          const rental = await getRental(id);

          if(rental.item.userProfile.user.id === authResponse.user.user.id || rental.userProfile.user.id === authResponse.user.user.id){
            setIsAuthorized(true)
          }
          else {
            setIsAuthorized(false);
          }
        } else {
          // If there's no ID, just authenticate the user
          setIsAuthorized(true);
        }
      } catch (error) {

        setIsAuthenticated(false);
        setIsAuthorized(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuthorization();
  }, [id]);


  if (loading) {
    return <CircularProgress />;
  }

  if (!isAuthenticated || !isAuthorized) {
    console.log("HERE IN REROUTING")
    return <Navigate to="/base/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RentalInformationProtection;