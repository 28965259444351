import { Typography, Grid, Button, Box, Divider, IconButton, TextField, InputAdornment, Avatar, Menu, MenuItem, useMediaQuery } from "@mui/material";
import { useNavigate  } from 'react-router-dom'; 
import React from "react";
import { useState, useEffect } from "react";
import { useStyles } from '../styles'; 
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SearchIcon from '@mui/icons-material/Search';
import { useUser } from '../features/User/userContext';
import { logoutUser } from "../features/User/actions";
import { mainComponentColor, mainHeadingColor } from "../theme";
import { initAutocomplete, loadScript } from "../features/util/locationHelpers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { dateOptions, dateOptionsShort, dateOptionsShortest, formatDateDB, formatDateNoTime } from "../features/util/formatters";
import logo from '../assets/Yelo_Logo.PNG'

export const getUserIcon = (isAuthenticated, user) => {
    if(isAuthenticated){
        if (user.image){
            return <img src={user.image} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        }
        else{
            return `${user.user.first_name[0]}${user.user.last_name[0]}`;
        } 
    }
    return null;
}

export const getLocation = (setLocation) => {
    if (typeof navigator !== 'undefined' && "geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
            setLocation(position)
        },
        (error) => {
            console.log("ERROR")
        }
      );
    } else {
        console.log("NO GEOLOCATION");
    }
  }; 


export default function Header(props) {

    const navigate = useNavigate();
    const classes = useStyles(); 
    const { user, isAuthenticated, logout, location, setLocation } = useUser();
    const [search, setSearch] = useState()
    const [anchorEl, setAnchorEl] = useState(null);

    const [searchLocation, setSearchLocation] = useState()
    const [selectedDate, setSelectedDate] = useState(null);
    const [expandSearchMobile, setExpandSearchMobile] = useState(false)

    const isXs = useMediaQuery(theme => theme.breakpoints.down('sm'))
    
    useEffect(() => {
        // console.log(location)
    }, [user, location])

    const setSearchLocationFunction =(name, latitude, longitude, placeIdTemp) => {
            const obj = {
                addressName: name,
                latitude: latitude,
                longitude: longitude,
                place_id: placeIdTemp
            };
            // setSearchLocation(searchLocationData => ({ ...searchLocationData, ...obj }))
            console.log("HERE IN LOCATION CHANGE FUNCTION")
            setSearch((prevSearch) => ({
                ...prevSearch,
                addressName: name,
                latitude: latitude,
                longitude: longitude,
                place_id: placeIdTemp
            }));
    }

    useEffect(() => {
        // Check if Google Maps script is already loaded
        if (document.getElementById('google-maps-script')) {
            if (window.google && window.google.maps && window.google.maps.places) {
                initAutocomplete(setSearchLocationFunction);
            } else {
                loadScript(setSearchLocationFunction);
            }
        } else {
            loadScript(setSearchLocationFunction);
        }
    }, [document.getElementById('autocompleteInput')]);
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleHome = () => {
        navigate('/home/')
    }

    const handleSearchEnter = () => {
        let obj = {...search}
        if(search.pickup){
            obj['pickup']= formatDateNoTime(search.pickup)
        }
        if(search.dropoff){
            obj['dropoff']=formatDateNoTime(search.dropoff)
        }
        const queryString = new URLSearchParams(obj).toString();
        setExpandSearchMobile(false)
        navigate(`search/?${queryString}`)
    }

    const handleOwnerProfile = () => {
        navigate('/ownerprofile/')
        handleClose()
    }

    const handleUser = (event) => {
        if (!isAuthenticated) {
            return navigate('/base/login/')
        }
        else{
            setAnchorEl(event.currentTarget);
        }
    }

    const handleLogout = ()=> {
        logoutUser().then(response => {logout(); navigate('/home/'); handleClose()})
    }

    const handleUpload = () => {
        if (!isAuthenticated) {
            return navigate('/base/login/')
        }
        navigate('/base/upload/')
    }

    const handleKeyDown = (event) => {
        // console.log('HERE')
        if (event.key === 'Enter') {
            const queryString = new URLSearchParams(search).toString();
            navigate(`search/?${queryString}`)
        }
    };

    const handleSearchDates = (field, event)=>{
        // const newDate = formatDateNoTime(event)
        setSearch((prevSearch) => ({
            ...prevSearch,
            [field]: event,
        }));
    }

    const handleSearchText = (field, event) => {
        setSearch((prevSearch) => ({
            ...prevSearch,
            [field]: event.target.value,
        }));
    }

    const getSearchTextDisplay = () => {
        let returnString = ``
        if(search){
            returnString += `${search.what.substring(0, 25)}`;
            if(search.addressName){
                returnString += `, ${search.addressName}`
            }
            if(search.pickup){
                const dateToReturn = new Date(search.pickup).toLocaleDateString("en-CA", dateOptionsShort)
                returnString += `, from ${dateToReturn}`
            }
            if(search.dropoff){
                
                if(search.pickup){
                    if (new Date(search.pickup).getMonth() == new Date(search.dropoff).getMonth()){
                        const dateToReturn = new Date(search.dropoff).toLocaleDateString("en-CA", dateOptionsShortest)
                        returnString += ` to ${dateToReturn}`
                    }
                    else{
                        const dateToReturn = new Date(search.dropoff).toLocaleDateString("en-CA", dateOptionsShort)
                        returnString += ` to ${dateToReturn}`
                    }
                }


            }
        }
        return returnString
    }

    const styleBottomRight = {
        position: "relative",
        width: "100%", // Keep the Grid full width
        "&::after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: "10%", // Push from the left to center the 80% width
            width: "80%", // Set border width to 80%
            borderBottom: "1px solid lightgrey",
        },
        "&::before": {
            content: '""',
            position: "absolute",
            right: 0,
            top:'10%',
            width: "1px",
            height: "80%",
            borderRight: "1px solid lightgrey",
        }
    }

    const styleBottom = {
        position: "relative",
        width: "100%", // Keep the Grid full width
        "&::after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: "10%", // Push from the left to center the 80% width
            width: "80%", // Set border width to 80%
            borderBottom: "1px solid lightgrey",
        },
    }
    const styleRight = {
        position: "relative",
        width: "100%", // Keep the Grid full width
        "&::before": {
            content: '""',
            position: "absolute",
            right: 0,
            top:'10%',
            width: "1px",
            height: "80%",
            borderRight: "1px solid lightgrey",
        }
    }

    const showCorrectSearch = () => {
        //mobile
        if(isXs){
            if(expandSearchMobile){
                const startDateValue = search && search.pickup ? new Date(search.pickup) : null 
                return (
                <Grid item component={"form"} container xs={12} mb={0.5}
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSearchEnter(search);
                  }}
                >
                    <Grid item xs = {6} ml={0.5} 
                        sx={styleBottomRight}
                    >
                        <TextField
                            label='What' 
                            onChange={(event) => {handleSearchText('what', event)}}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            variant="standard"
                            sx={{ 
                                "& .MuiInputLabel-root": { 
                                    textAlign: "center", 
                                    width: "100%",
                                },
                                "& .MuiInputBase-root": {
                                    borderRadius: "20px", // Adjust the radius as needed
                                },
                            }}
                            size='small'
                            autoComplete="off"
                            value = {search ? search.what : null}
                            required
                        />
                    </Grid>
                    <Grid item xs = {5} ml={.5}  sx={styleBottom}>
                            <TextField
                                id="autocompleteInput" 
                                label='Where' 
                                onChange={(event) => {setSearchLocationFunction(event.target.value, null, null, null)}}
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment: <div id="google-maps-script"/>  
                                }} 
                                variant="standard"
                                sx={{
                                    "& .MuiInputLabel-root": { 
                                        textAlign: "center", 
                                        width: "100%" 
                                    }
                                }}
                                size='small'
                                value = {search ? search.addressName : null}
                            />
                    </Grid>
                    <Grid item xs = {6} ml={0.5}  sx={styleRight}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                            label="Pick up"
                            value={search ? search.pickup: null}
                            views={["month", "day"]}
                            onChange={(newValue) => handleSearchDates('pickup', newValue)}
                            slotProps={{ textField: { variant: 'standard',
                                                    InputProps:{disableUnderline: true}, 
                                                    sx:{"& .MuiInputLabel-root": { 
                                                            textAlign: "center", 
                                                            width: "100%" 
                                                        }},
                                                        size:'small'
                                                    } 
                                    }}
                        />
                </LocalizationProvider>
                    </Grid>
                    <Grid item xs ={5} ml= {0.5}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                            label="Dropoff"
                            value={ search  ? search.dropoff : null}
                            views={["month", "day"]}
                            onChange={(newValue) => handleSearchDates('dropoff',newValue)}
                            slotProps={{ textField: { variant: 'standard',
                                                    InputProps:{disableUnderline: true}, 
                                                    sx:{"& .MuiInputLabel-root": { 
                                                            textAlign: "center", 
                                                            width: "100%" 
                                                        }},
                                                        size:'small'
                                                    } 
                                    }}
                        />
                </LocalizationProvider>
                    </Grid>
                    <Grid item xs = {0.5} mt={0.7} style={{ display: 'flex', justifyContent: 'center'}}>
                        <IconButton type="submit">
                            <SearchIcon fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
                )
            }
            else{
                return(
                    <Grid item container xs={12} mb={0.5}>
                        <Grid item xs = {11} md = {12}>
                            <TextField
                                label='What          Where          When' 
                                onClick={() => setExpandSearchMobile(true)}
                                fullWidth
                                InputProps={{ disableUnderline: true, style: { textAlign: 'center', width: '100%' },}}
                                variant="standard"
                                sx={{ 
                                    "& .MuiInputLabel-root": { 
                                        textAlign: "center", 
                                        width: "100%",
                                        letterSpacing: "0.1em",
                                        whiteSpace: "pre",
                                    },
                                    "& .MuiInputBase-root": {
                                        borderRadius: "20px", // Adjust the radius as needed
                                    },
                                }}
                                value = {getSearchTextDisplay()}
                                size='small'
                            />
                        </Grid>
                        <Grid item xs = {1} mt={0.7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton onClick = {() => {setExpandSearchMobile(true)}}>
                            <SearchIcon/>
                        </IconButton>
                    </Grid>
                    </Grid>
                )
            }
        }
        //pc or laptop
        else{
            return(
                <Grid item container xs={12} mb={0.5}>
                    <Grid item xs = {3} md = {3.5}>
                        <TextField
                            label='What' 
                            onChange={(event) => {handleSearchText('what', event)}}
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    padding: '0 10px', // Optional padding
                                }
                            }}
                            variant="standard"
                            sx={{ 
                                "& .MuiInputLabel-root": { 
                                    textAlign: "center", 
                                    width: "100%",
                                },
                                "& .MuiInputBase-root": {
                                    borderRadius: "20px", // Adjust the radius as needed
                                },
                            }}
                            size='small'
                        />
                    </Grid>
                    <Grid item xs = {0.5} container alignItems="center" justifyContent="center">
                        <Divider 
                            orientation="vertical"
                            sx={{ height: '40%' }}
                        />
                    </Grid>
                    <Grid item xs = {3}>
                            <TextField
                                id="autocompleteInput" 
                                label='Where' 
                                // onChange={(event) => {handleSearchChange('where', event)}}
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment: <div id="google-maps-script"/>  
                                }} 
                                variant="standard"
                                sx={{
                                    "& .MuiInputLabel-root": { 
                                        textAlign: "center", 
                                        width: "100%" 
                                    }
                                }}
                                size='small'
                            />
                    </Grid>
                <Grid item xs = {0.5} container alignItems="center" justifyContent="center">
                    <Divider 
                        orientation="vertical"
                        sx={{ height: '40%' }}
                    />
                </Grid>
                <Grid item xs = {1.75} md={1.5}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                    label="Pick up"
                    value={search ? search.pickup: null}
                    views={["month", "day"]}
                    onChange={(newValue) => handleSearchDates('pickup', newValue)}
                    slotProps={{ textField: { variant: 'standard',
                                            InputProps:{disableUnderline: true}, 
                                            sx:{"& .MuiInputLabel-root": { 
                                                    textAlign: "center", 
                                                    width: "100%" 
                                                }},
                                                size:'small'
                                            } 
                            }}
                />
                </LocalizationProvider>
                </Grid>
                <Grid item xs = {0.5} container alignItems="center" justifyContent="center">
                    <Divider 
                        orientation="vertical"
                        sx={{ height: '40%' }}
                    />
                </Grid>
                <Grid item xs = {1.75} md= {1.5}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                    label="Dropoff"
                    value={ search ? search.dropoff: null}
                    views={["month", "day"]}
                    onChange={(newValue) => handleSearchDates('dropoff',newValue)}
                    slotProps={{ textField: { variant: 'standard',
                                            InputProps:{disableUnderline: true}, 
                                            sx:{"& .MuiInputLabel-root": { 
                                                    textAlign: "center", 
                                                    width: "100%" 
                                                }},
                                                size:'small'
                                            } 
                            }}
                />
                </LocalizationProvider>
                </Grid>
            <Grid item xs = {1} mt={0.7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick = {() => {handleSearchEnter(search)}}>
                    <SearchIcon/>
                </IconButton>
            </Grid>
                </Grid>
            )
        }
    }

    // console.log(search)

    
    return (
        <>
        <Grid container style = {{direction: 'row', alignItems: 'center', height: '100%', width:'100%', display: 'flex', justifyContent: 'space-between' }}>
            <Grid item xs = {2} sm = {1} md = {1}>
                <Button onClick = {() => handleHome()} style={{ textTransform: 'none' }}>
                    {/* <Typography variant ="h1" align="center">
                        Yelo
                    </Typography> */}
                    <img src={logo} alt="Logo" 
                    style={{
                    maxHeight: '100%', // Ensures the logo doesn't exceed the height of the grid
                    maxWidth: '100%',  // Keeps the logo proportional
                    objectFit: 'contain' // Maintains aspect ratio while fitting within bounds
                }}/>
                </Button>
            </Grid>
            <Grid
                item
                // display={{ xs: "block", sm:'block', md: 'block' }} 
                // sm = {0.5} 
                // md = {0.5} 
                style={{ height: '100%' }}
            >
                <IconButton color="mainComponentColor" aria-label="map" onClick = {()=>{getLocation(setLocation)}}>
                    <LocationOnIcon style={{ color: location ? '#ebb134' : 'mainComponentColor' }}/> 
                    <Typography variant = 'locationText' style={{ color: location ? '#ebb134' : 'mainComponentColor' }}>
                        Meet near me
                    </Typography>
                </IconButton>
            </Grid>
            <Grid item xs={2.5} sm = {2} md = {2} ml ={0.5} style={{ height: '100%' }}>
                <IconButton color={mainComponentColor} aria-label="map" onClick = {()=>{handleUpload()}}>
                <Box 
                    component={Grid}
                    item
                    display={{ xs: "none", sm: "none", md :"block" }} 
                >
                    +
                </Box>
                    <Typography variant = 'locationText'> Add Listing</Typography>
                </IconButton>
            </Grid>
                <IconButton onClick = {(event) => {handleUser(event)}}>
                    <Avatar  style={{ backgroundColor: mainComponentColor }}>
                        {getUserIcon(isAuthenticated, user ? user.user: null)}
                    </Avatar>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => {handleOwnerProfile()}}>
                        Owner Profile
                    </MenuItem>
                    <MenuItem onClick={() => {navigate('renterprofile/')}}>
                        Renter Profile
                    </MenuItem>
                    <MenuItem onClick={() => {navigate('faqs/')}}>
                        FAQs
                    </MenuItem>
                    <MenuItem onClick={() => {handleLogout()}} style={{ color: 'red' }}>
                        Logout
                    </MenuItem>
                </Menu>
        </Grid>
        { !props.noSearch ? 
            <Grid container style = {{direction: 'row', alignItems: 'center', height: '100%', width:'100%', display: 'flex', justifyContent: 'center' }}>
                <Grid container item ml = {1} mr={1} xs={12} sm = {9} md = {9} mb={1} style={{borderRadius: '20px', border:`1px solid ${mainHeadingColor}`, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)' }}>
                   {showCorrectSearch()}
                </Grid>
            </Grid>
        : null }
    </>
    )
}
