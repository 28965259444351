import React, { useState, useEffect } from 'react';
import { useNavigate  } from 'react-router-dom'; 
import { Typography, Grid, Box, IconButton } from '@mui/material';
import { useStyles } from '../styles'; 
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import BuildIcon from '@mui/icons-material/Build';
import TvIcon from '@mui/icons-material/Tv';

const CategoryStrip = () => {
    const classes = useStyles();
    const navigate = useNavigate();
  
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    return (
        <>
        <Grid container mt={3} gap={4} alignItems="center" justifyContent="center">
                    <Box display='flex' flexDirection={'column'} alignItems={'center'} gap={1}>
                        <IconButton
                        sx={{
                            width: 75,  
                            height: 75,
                            borderRadius: '50%', 
                            border: '2px solid gray',         
                            backgroundColor: 'transparent',
                            color:'gray',
                            '&:hover':{
                                backgroundColor:'rgba(211,211,211,0.2)'
                            }
                        }}
                        onClick={()=>{navigate('/category/3')}}
                        >
                            <DirectionsBikeIcon style={{fontSize: 45, color:'gray'}} />
                            
                        </IconButton> 
                        <Typography variant = 'locationText' color={'gray'}> Outdoors</Typography>
                    </Box>
                    <Box display='flex' flexDirection={'column'} alignItems={'center'} gap={1}>
                        <IconButton
                        sx={{
                            width: 75,
                            height: 75,
                            borderRadius: '50%', 
                            border: '2px solid gray',         
                            backgroundColor: 'transparent',
                            color:'gray',
                            '&:hover':{
                                backgroundColor:'rgba(211,211,211,0.2)'
                            }
                        }}
                        onClick={()=>{navigate('/category/1')}}
                        >
                            <BuildIcon style={{fontSize: 45, color:'gray'}} />
                            
                        </IconButton> 
                        <Typography variant = 'locationText' color={'gray'}>Tools</Typography>
                    </Box>
                    <Box display='flex' flexDirection={'column'} alignItems={'center'} gap={1}>
                        <IconButton
                        sx={{
                            width: 75,  
                            height: 75,
                            borderRadius: '50%', 
                            border: '2px solid gray',         
                            backgroundColor: 'transparent',
                            color:'gray',
                            '&:hover':{
                                backgroundColor:'rgba(211,211,211,0.2)'
                            }
                        }}
                        onClick={()=>{navigate('/category/4')}}
                        >
                            <TvIcon style={{fontSize: 45, color:'gray'}} />
                            
                        </IconButton> 
                        <Typography variant = 'locationText' color={'gray'}>Household</Typography>
                    </Box>
                </Grid>
    </>
    );
  };
  
  export default CategoryStrip;