import React from "react";
import { useState, useEffect } from "react";
import { Grid, Box, CircularProgress, useMediaQuery, IconButton, Typography } from '@mui/material';
import { useUser } from '../../User/userContext';
import Resizer from 'react-image-file-resizer';
import { useStyles } from '../../../styles';
import ImageViewer from "../../../components/ImageViewer";
import WelcomeBanner from "../../../components/WelcomeBanner";
import ItemBanner from "../../../components/ItemBanner";
import CategoryStrip from "../../../components/CategoryStrip"
import { getItemsHomePage } from "../actions";
import ItemBannerVerticalEndless from "../../../components/ItemBannerVerticalEndless";
import { useNavigate } from "react-router-dom";

export default function Home() {

    const [isLoading, setIsLoading] = useState(true)
    const [items, setItems] = useState()
    const classes = useStyles(); 
    const navigate = useNavigate()

    const { user, location } = useUser();

    useEffect(() => {
        if(location){
            const origin = (location.coords.latitude).toString() + ',' + (location.coords.longitude).toString()
                getItemsHomePage(origin).then(response =>{
                    setItems(response)
                })
        }
        else{
            getItemsHomePage(false).then(response =>{
                setItems(response)
            })
        }
        const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-55CFETEYNR";
    document.head.appendChild(script);

    // Initialize Google Analytics
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-55CFETEYNR");
    };

    // Clean up the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
    }, [location])

    const banners = [
        'Why Buy? Just Rent!',
        'Borrow What You Need, Share What You Have',
        'A Community Only Needs 3 Hammers',
    ];

    const isXs = useMediaQuery(theme => theme.breakpoints.down('sm'))

    return (
        <>
        <Grid container>
                <Grid container mt={3} alignItems="center" justifyContent="center">
                    <Grid item xs = {12}>
                        <WelcomeBanner banners={banners}/>
                    </Grid>
                </Grid>
                <CategoryStrip/>
                <Grid container mt = {3} alignItems="center" justifyContent="center">
                    <Grid item xs = {11}>
                        <ItemBannerVerticalEndless items={items} setXs={isXs ? 12 : 4}/>
                    </Grid>
                </Grid>
        </Grid>
    </>)
}

