import React, { useState, useEffect } from 'react';
import { Typography, Paper, Grid, Box, CircularProgress, IconButton } from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import { useStyles } from '../styles'; 
import ItemCard from './ItemCard';
import RentalCard from './RentalCard';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { circularSlice } from '../features/util/bannerHelpers';
import { backgroundColor } from '../theme';

export const determineItemsToShow = () => {
  // Adjust these values based on your design and preferences
  if (window.innerWidth < 600) {
    return 1;
  } else if (window.innerWidth < 960) {
    return 3;
  } else {
    return 4;
  }
};

const ItemBanner = ({items, scroll, circular, rentalInfo, numberToShow, showEdit, showDelete, refreshBanner}) => {
    const classes = useStyles();

    const num = numberToShow ? numberToShow : determineItemsToShow()

    const [itemShowing, setItemShowing] = useState([])
    const [itemIndex, setItemIndex] = useState(0)
    const [isLoading, setIsLoading] = useState(true);
    const [intervalId, setIntervalId] = useState();


    useEffect(() => {
      if(items){
          if(circular){
            setItemShowing(circularSlice(items, itemIndex, itemIndex + num))
          }
          else{
            setItemShowing(items.slice(itemIndex, itemIndex + num))
          }
          setIsLoading(false)
      }
    }, [items]);
  

    useEffect(() => {
      if(items){
        if(circular){
          setItemShowing(circularSlice(items, itemIndex, itemIndex + num))
        }
        else{
          setItemShowing(items.slice(itemIndex, itemIndex + num))
        }
      }
      }, [itemIndex]);

    const handleScroll = direction => {
      // Update itemIndex based on the scroll direction
      const newIndex =
        direction === 'left'
          ? (itemIndex - 1 + items.length) % items.length
          : (itemIndex + 1) % items.length;
      setItemIndex(newIndex);
    };

    const handlers = useSwipeable({
      onSwipedLeft: () => handleScroll('right'),
      onSwipedRight: () => handleScroll('left'),
      preventDefaultTouchmoveEvent: true,
      trackMouse: true
    });
  
    return (
        <>
        {isLoading ?
            <Box sx={{display: 'flex', justifyContent: 'center' }}>
                <CircularProgress style={{ color: 'black' }}/>
            </Box> 
        :   
        <Paper
          {...handlers}
          style={{backgroundColor: backgroundColor, borderRadius: '10', height: '100%', display: 'flex', flexDirection: 'row', position: 'relative', boxShadow: 'none', gap:'10px'}}
        >
            <IconButton onClick={() => handleScroll('left')} disabled = {circular ? false : itemIndex === 0}>
              <ArrowLeftIcon />
            </IconButton>
            {itemShowing.map((item, index) => 
              <Grid container 
                key={item.id}
                style= {{maxWidth:'355px'}}
              >
                {rentalInfo ? <RentalCard key={item.id} rh={item} index={index}/> : <ItemCard key={item.id} item={item} index={index} showEdit showDelete refreshBanner={refreshBanner}/>}
              </Grid>
            )}
            <IconButton onClick={() => handleScroll('right')} disabled = {circular ? false : itemIndex + num >= items.length}>
              <ArrowRightIcon />
            </IconButton>
        </Paper>
    }
    </>
    );
  };
  
  export default ItemBanner;