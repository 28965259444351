import { makeStyles } from "@mui/styles";
import { contrastingColor, mainHeadingColor, backgroundColor } from "./theme";

export const useStyles = makeStyles((theme) => ({
    root: {
      // // backgroundImage: backgroundItems,
      // backgroundColor: backgroundColor,
      // backgroundSize: 'cover',
      // backgroundRepeat: 'no-repeat',
      // backgroundPosition: 'center',
      minHeight: '100vh',
    },
    eggShell: {
      backgroundColor: '#faf8f2 !important'
    },
    whiteBackgroundTextField: {
      background: '#faf8f2', // Set the background color to white
    },
    imageRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 2,
      justifyContent:'center'
    },
    imageMedia: {
      width: '100%',
      maxHeight: '350px',
      objectFit: 'contain'
    },
    imageCardContent: {
      // position: 'relative',
      maxWidth: '100%',
      height: 'auto',
    },
    itemCard: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      // boxShadow: 'none', // Remove box shadow if needed
    },
    ImageNavigation: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 2,
    },
    banner: {
      // padding: 2,
      textAlign: 'center',
      // display: 'flex',
      // flexDirection: 'column',
      // justifyContent: 'center',
    },
    miniBanner:{
      textAlign: 'center',
      backgroundColor: mainHeadingColor +' !important',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center', 
    },
    // itemBannerImage : { 
    //   width: '100%',
    //   height: '250px', // Set the height to your desired value
    //   objectFit: 'cover',
    //   backgroundColor: '#F8BE37 !important' 
    // },
    circularImage: {
      width: '100%',
      height: '200px',
      objectFit: 'cover',
    },
    messageContainer: {
      height: '75vh', // 100% of viewport height for xs
      overflowY: 'auto'
    },
    purpleBorder : {
      borderRadius: '10px',
      border: `2px solid ${mainHeadingColor}`,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    cardElement:{
      backgroundColor: 'white',
      padding: '10px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      boxShadow: '0 1px 3px 0 #e6ebf1',
      transition: 'box-shadow 150ms ease'
    },
    facebookButton: {
      backgroundColor: 'white',
      color: '#4267b2',
      border: '1px solid #D3D3D3',
      borderRadius: '4px',
      width: '300px',
      padding: '10px 20px',
      fontSize: '16px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px'
    },
    customMarker: {
      backgroundColor: 'white',
      padding: '4px',
      border: '1px solid black',
      borderRadius: '8px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
      fontSize: '15px',
    },
    infinitScroll: {
      gap: '15px',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
    }

  })
);

export const getMapOptions = (latitude, longitude) => {
  return {center: { lat: parseFloat(latitude,10), lng: parseFloat(longitude,10) },
  zoom: 15, // Adjust the zoom level as necessary
  disableDefaultUI: true,
  styles: [
      // Customize the color palette using Google Maps Styling
      {
          featureType: 'all',
          elementType: 'all',
          stylers: [
              { hue: mainHeadingColor }, // Adjust the hue as needed
              { saturation: -60 }, // Adjust the saturation as needed
          ],
      },
  ],}
};

export const getMapOptionsSearch = (latitude, longitude, items) => {
  const mapOptions = {
    center: { lat: parseFloat(latitude, 10), lng: parseFloat(longitude, 10) },
    zoom: 14, // Adjust the zoom level as necessary
    mapId: '83f703aa24daac34',
    disableDefaultUI: true,
    styles: [
      // Customize the color palette using Google Maps Styling
      {
        featureType: 'all',
        elementType: 'all',
      },
    ],

  };


  const markers = items.map(item => ({
    position: { lat: parseFloat(item.latitude, 10), lng: parseFloat(item.longitude, 10) },
    title: item.name,
    pricePerDay: item.pricePerDay,
  }));

  return { mapOptions, markers };
};