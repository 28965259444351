import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; 
import { getItemsByCategory, getPopularCat } from '../actions'
import { Typography, Avatar, Grid, Box, CircularProgress, Button, Paper, useMediaQuery } from '@mui/material';
import { useStyles } from '../../../styles';
import ItemCard from '../../../components/ItemCard';
import ItemBanner from '../../../components/ItemBanner';
import { useUser } from '../../User/userContext';
import ItemBannerVerticalEndless from '../../../components/ItemBannerVerticalEndless';
import CategoryStrip from '../../../components/CategoryStrip';

const Category = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {id} = useParams()

    const [items, setItems] = useState([]);
    const [popular, setPopular] = useState([])
    const [category, setCategory] = useState()
    const [isLoading, setIsLoading] = useState(true);

    const { location } = useUser();
    const isXs = useMediaQuery(theme => theme.breakpoints.down('sm'))

    useEffect(() => {
        if(location){
            const origin = (location.coords.latitude).toString() + ',' + (location.coords.longitude).toString()
            getItemsByCategory(origin, id).then(response =>{
                setItems(response)
                setCategory(response[0] ? response[0].cat_name : null)
                setIsLoading(false)
            })
        }
        else{
            getItemsByCategory(false, id).then(response =>{
                setItems(response)
                setCategory(response[0] ? response[0].cat_name : null)
                setIsLoading(false)
            })
        }
    }, []);

    const handleClick = (id) => {
        navigate(`../item/${id}`)
    }
  
    return (
        <Grid container>
            <Grid item xs ={12} mt={1} mb ={1}>
                <Typography variant ="h1" align="center">
                    {category}
                </Typography >
            </Grid>
            <Grid container mt = {3} alignItems="center" justifyContent="center">
                <Grid item xs = {11}>
                    <ItemBannerVerticalEndless items={items} setXs={isXs ? 12 : 4}/>
                </Grid>
            </Grid>
        </Grid>
    );
  };
  
  export default Category;